import ajax from '@/utils/ajax.js'

/**
 * 获取区域的在售商品
 * @param {*} params 
 * @returns 
 */
export function getRegionalGoodsList(params) {
  return ajax.post('/RegionalOutletsGoods/getList',params)
}


/**
 * 给区域的门店上架商品：蛋糕
 * @param {*} params 
 * @returns 
 */
export function addRegionalCakeGoods(params) {
  return ajax.post('/RegionalOutletsGoods/onSaleCakeGoods',params)
}

/**
 * 给区域的门店上架商品：非蛋糕
 * @param {*} params 
 * @returns 
 */
export function addRegionalOtherGoods(params) {
  return ajax.post('/RegionalOutletsGoods/onSaleOtherGoods',params)
}

/**
 * 给区域的门店下架商品
 * @param {*} params 
 * @returns 
 */
export function removeRegionalGoods(params) {
  return ajax.post('/RegionalOutletsGoods/offShelvesGoods',params)
}

/**
 * 给区域的门店上架商品：查看商品sku
 * @param {*} params 
 * @returns 
 */
export function getGoodsSkuList(params) {
  return ajax.post('/RegionalOutletsGoods/getSkuListOnSaleUse',params)
}

/**
 * 查看某商品在全市所有网店的上架状态
 * @param {*} params 
 * @returns 
 */
export function getGoodsSkuInAllShopsStatus(params) {
  return ajax.post('/RegionalOutletsGoods/getGoodsAtOutletsCityOutletsSaleStatusList',params)
}

/**
 * 查看某商品在城市的上架库存
 * @param {*} params 
 * @returns 
 */
export function getGoodsOnSaleStock(params) {
  return ajax.post('/RegionalOutletsGoods/goodsOutletsOnSaleStock',params)
}

/**
 * 限量预售：城市总库存：商品列表
 * @param {*} params 
 * @returns 
 */
export function getCityPresaleGoods(params) {
  return ajax.post('/OutletsCityGoodsSkuStock/getGoodsList',params)
}

/**
 * 限量预售：城市总库存：设置库存
 * @param {*} params 
 * @returns 
 */
export function editCityPresaleStock(params) {
  return ajax.post('/OutletsCityGoodsSkuStock/editGoodsStock',params)
}
